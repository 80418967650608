<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix dashboard-card-title">
				<svg-icon icon-class="github" class="dashboard-icon-card" />
				<span class="head-title">Marketplace API</span>
			</div>
			<div class="card-body">
				<div class="mini-title">PHP API Wrapper</div>
				{{$t('dashboard.right.wrapper-description')}}
				<p>
					<a rel="noreferrer" href="https://github.com/celdotro/marketplace" target="_blank">
						<el-button plain class="fullWidth" type="primary">{{$t('general.details')}}</el-button>
					</a>
				</p>

			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {

			};
		},
		computed: {

		},
		methods: {

		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.dashboard-icon-card {
		font-size: 27px !important;
		vertical-align: middle;
		color: #ff601d;
	}

	.head-title {
		vertical-align: middle;
		font-weight: 500;
		margin-left: 3px;
	}

	.mini-title {
		font-size: 14px;
		font-weight: 500;
		padding-bottom: 10px;
	}

	.card-body {
		font-size: 13px;
	}

	a {
		color: #ff601d;
	}
</style>